import { Router } from '@angular/router';
import { AppUrls } from 'src/app/helpers/app-urls';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, Observable, EMPTY, firstValueFrom, from, lastValueFrom, delay } from 'rxjs';
import { EncryptDecrypt } from '../helpers/encrypt-decrypt';
import { CookieService } from 'ngx-cookie-service';
import { Generic } from '../helpers/generic';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  // service contructor
  constructor(
    // http client service
    private httpClient: HttpClient,
    // controller for toast messages
    private toastController: ToastController,
    // service for getting translated text
    private translateService: TranslateService,
    // controller used to dismiss any / all loading icons
    private loadingCtrl: LoadingController,
    private router: Router,
    private cookieService: CookieService,
    private genericService: Generic
  ) {
    // constructor
  }

  // implementation to intercept all http requests
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('assets/i18n/null')) {
      request = request.clone({url: request.url.replace('null', 'en')});
    }

    if (request.url.includes('62.171.135.59')) {
      return EMPTY;
    }

    return from(this.handleInternetConnectionCheck(request, next)).pipe(
      map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
              // ok
          }
          return event;
      }),
      catchError(async (error: HttpErrorResponse) => {
        this.genericService.logEvent('httpInterceptorError', { error: JSON.stringify(error) });
        if (error.status === 401) {
          this.genericService.clearAndNavigateLoginRoot();

          return null;
        } else if (error.message.includes('Http failure during parsing for http://localhost/undefined/api/sessiontype')) {
          return null;
        }

        throw error;
      }));
  }

  // function for checking internet connectivity
  async handleInternetConnectionCheck(request: HttpRequest<any>, next: HttpHandler) {

    // the 'healthcheck' path is the path to the API that is used to ensure
    // that the device is not only connected to a network but that
    // there is also an internet connection
    if (request.url.includes('assets') || request.url.includes('healthcheck') || this.router.url === '/'
     || request.url.includes('validateuser')) {
        // skip if requests is to the assets folder or to an API that is used to ensure that there is access to the internet
    } else {
      let isconnected = true;
      let del = 0;
      // check if there is a connection to a network or not
      if (navigator.onLine) {
        // check if there is internet access
        const res = await firstValueFrom(this.checkInternetAccess()).catch(err => console.log(err));
        if (res === undefined || !res) {
          isconnected = false;
        }
      } else {
        isconnected = false;
        del = 500;
      }

      if (!isconnected) {
        // delay to ensure that all loading elements have been displayed before removing them
        delay(del);

        // get all loading elements
        let topLoader = await this.loadingCtrl.getTop();
        let count = 0;
        while (topLoader) {
          // dismiss loading element
          if (!(await topLoader.dismiss())) {
            console.log('Could not dismiss the topmost loader. Aborting...');
            count += 1;
          }

          if (count < 10) {
            topLoader = await this.loadingCtrl.getTop();
          } else {
            topLoader = null;
          }
        }

        localStorage.setItem('connection', 'n');
        // display toast message with no connection message
        this.presentToast();

        // terminate request
        return EMPTY;
      } else {
        // internet connection available
        // continue process
        localStorage.setItem('connection', 'y');
      }

      // token implementation here
      const ed = new EncryptDecrypt();

      const tk = this.cookieService.get('htrgjktzhrt');
      if (!tk) {
        // this.router.navigateByUrl('login')
        // window.location.reload()
        this.genericService.logEvent('tokenError', { error: 'token not available' });
        this.genericService.clearAndNavigateLoginRoot();
        return;
      }

      const token = ed.decrypt(tk);

      if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
      } else {
          // reload to logout
          // window.location.reload()
          this.genericService.logEvent('tokenError', { error: 'token not available' });
          this.genericService.clearAndNavigateLoginRoot();
      }
    }

    return lastValueFrom(next.handle(request));
  }

  checkInternetAccess() {
    const urls = new AppUrls();
    const url = urls.apiUrl + '/api/healthcheck/';
    return this.httpClient.get<any>(url);
  }

  // function for displaying toast message
  async presentToast() {
    // get translated no connection message
    const msg = await firstValueFrom(this.translateService.get('GENERAL.NOCONNECTION'));
    this.genericService.logEvent('noConnectionError', { info: 'no connection' });

    // display no connection message
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: 'warning',
      position: 'bottom',
      cssClass: 'ion-text-center',
    });
    toast.present();
  }

}
