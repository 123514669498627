import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
/* eslint-disable @typescript-eslint/naming-convention */

import { NgModule, enableProdMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';

import { SQLite } from '@ionic-native/sqlite/ngx';

import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';

import { IonicStorageModule } from '@ionic/storage-angular';
import { NFC, Ndef } from '@awesome-cordova-plugins/nfc/ngx';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpInterceptorService } from './interceptors/http-interceptor.service';
import { DataCache } from './helpers/data-cache';
import { GenericFunctionsService, UserService } from '@wtm/wtmlibrary';
import { UserPage } from './attendances/manual-attendance/user/user/user.page';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { File } from '@awesome-cordova-plugins/file/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

enableProdMode();

@NgModule({
  declarations: [
      AppComponent,
      UserPage
   ],
  entryComponents: [],

  // eslint-disable-next-line max-len
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (LanguageLoader),
        deps: [HttpClient]
      }
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SQLite,
    SQLitePorter,
    NFC,
    Ndef,
    DatePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Network,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    AppVersion,
    SplashScreen,
    DataCache,
    UserService,
    GenericFunctionsService,
    File,
    AndroidPermissions
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    });
  }
}


