import { Constants } from '../helpers/constants';
import { Cookies } from '../helpers/cookies';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AppUrls } from 'src/app/helpers/app-urls';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, firstValueFrom, empty, EMPTY } from 'rxjs';
import { Profile } from 'src/app/models/profile';
import { Roles } from '../models/Roles';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceRefresher {
  apiUrl: string;
  email: string;
  phone: string;
  pass: string;

  constructor(
    private httpclient: HttpClient,
    private cookies: Cookies,
    private router: Router,
    private authService: AuthService
  ) {
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;
  }

  authenticate() {
    // tslint:disable-next-line: max-line-length
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;

    const constants = new Constants();
    const user = this.cookies.getCookie(constants.userDataName);

    this.phone = null;
    this.email = localStorage.getItem('email');
    this.pass = localStorage.getItem('password');

    if (this.email == null || this.pass == null) {
      this.email = 'ranitesdefault@this.email.com';
      this.pass = 'ran';
      return EMPTY;
    }
    const url =
      this.apiUrl +
      '/api/user/auth/' +
      this.email +
      '/' +
      this.phone +
      '/' +
      this.pass +
      '/';
    return this.httpclient.get<any>(url).pipe(
      tap(
        // Log the result or error
        (error) => this.handleError(error, 1)
      )
    );
  }

  async refresherFunc() {
    const constants = new Constants();

    // validate usm user
    let usr: any;
    try {
      this.email = localStorage.getItem('email');
      this.pass = localStorage.getItem('password');
      if (this.email && this.pass) {
        usr = await this.authService.getUser(this.email, this.pass);
      }
    } catch (error) {
      console.log('REFRESH FUNC ERROR 1', error);
    }

    let res = null;
    try {
      res = await firstValueFrom(this.authenticate())
      .catch((err) => this.handleError(err));
    } catch (error) {
      console.log('REFRESH FUNC ERROR 2', error);
    }

    if (res !== undefined && res !== null) {
      // // successful

      const user = new User();
      user.confCode = res.confCode;
      user.created = res.created;
      user.email = res.email;
      user.firstName = res.firstName;
      user.isEnabled = res.isEnabled;
      user.lastLogin = res.lastLogin;
      user.lastName = res.lastName;
      user.password = res.password;
      user.phone = res.phone;
      user.userId = res.userId;

      localStorage.setItem('usersessionid', res.userId);
      // store user data
      this.cookies.setCookie(constants.userDataName, user);

      // roles

      const userrole = new Roles();

      userrole.timeTrackingSection = false;
      userrole.timeTrackingExpert = false;
      userrole.timeLoggingSection = false;
      userrole.timeLoggingExpert = false;
      userrole.terminalSection = false;
      userrole.sessionSection = false;
      userrole.sessionExpert = false;
      userrole.checkPointSection = false;
      userrole.accountSection = false;
      userrole.accountExpert = false;
      userrole.statisticsSection = false;
      userrole.cardSection = false;
      userrole.cardExpert = false;
      userrole.personSection = false;
      userrole.personExpert = false;
      userrole.url = false;
      userrole.absenceExpert = false;

      userrole.calendarSection = false;
      userrole.calendarExpert = false;

      userrole.sessionTypeSection = false;
      userrole.sessionTypeExpert = false;

      userrole.configSection = false;
      userrole.configExpert = false;

      userrole.profileSection = false;
      userrole.profileExpert = false;

      userrole.complianceSection = false;
      userrole.complianceExpert = false;

      if (usr && usr.userRoles !== null && usr.userRoles.length > 0) {
        for (const role of usr.userRoles) {
          // checking time tracking roles
          if (
            role.roleName === 'WTM.TimeTracking.View'
          ) {
            userrole.timeTrackingSection = true;
          }

          if (
            role.roleName === 'WTM.TimeTracking.Edit'
          ) {
            userrole.timeTrackingExpert = true;
          }

          // checking time logging roles

          if (
            role.roleName === 'WTM.TimeLogging.View'
          ) {
            userrole.timeLoggingSection = true;
          }

          if (
            role.roleName === 'WTM.TimeLogging.Edit'
          ) {
            userrole.timeLoggingExpert = true;
          }

          // checking terminal roles

          if (
            role.roleName === 'WTM.Terminal.View'
          ) {
            userrole.terminalSection = true;
          }

          // checking session roles
          if (
            role.roleName === 'WTM.Session.View'
          ) {
            userrole.sessionSection = true;
          }

          if (
            role.roleName === 'WTM.Session.Edit'
          ) {
            userrole.sessionExpert = true;
          }

          // checking checkpoint roles
          if (
            role.roleName === 'WTM.Checkpoint.View'
          ) {
            userrole.checkPointSection = true;
          }

          // account roles
          if (
            role.roleName === 'WTM.Account.View'
          ) {
            userrole.accountSection = true;
          }

          if (
            role.roleName === 'WTM.Account.Edit'
          ) {
            userrole.accountExpert = true;
          }

          // statistics
          if (
            role.roleName === 'WTM.Statistics.View'
          ) {
            userrole.statisticsSection = true;
          }

          // card permission
          if (
            role.roleName === 'WTM.Card.View'
          ) {
            userrole.cardSection = true;
          }

          if (
            role.roleName === 'WTM.Card.Edit'
          ) {
            userrole.cardExpert = true;
          }

          // person permission
          if (
            role.roleName === 'WTM.Person.View'
          ) {
            userrole.personSection = true;
          }

          if (
            role.roleName === 'WTM.Person.Edit'
          ) {
            userrole.personExpert = true;
          }

          // url permission
          if (
            role.roleName === 'WTM.URL.Edit'
          ) {
            userrole.url = true;
          }

          // absence permission
          if (
            role.roleName === 'WTM.Absence.Edit'
          ) {
            userrole.absenceExpert = true;
          }

          // session type permission
          if (
            role.roleName === 'WTM.SessionType.View'
          ) {
            userrole.sessionTypeSection = true;
          }

          if (
            role.roleName === 'WTM.SessionType.Edit'
          ) {
            userrole.sessionTypeExpert = true;
          }

          // config permission
          if (
            role.roleName === 'WTM.Configuration.View'
          ) {
            userrole.configSection = true;
          }

          if (
            role.roleName === 'WTM.Configuration.Edit'
          ) {
            userrole.configExpert = true;
          }

          // profile permission
          if (
            role.roleName === 'WTM.Profile.View'
          ) {
            userrole.profileSection = true;
          }

          if (
            role.roleName === 'WTM.Profile.Edit'
          ) {
            userrole.profileExpert = true;
          }

          // compliance permission
          if (
            role.roleName === 'WTM.Compliance.View'
          ) {
            userrole.complianceSection = true;
          }

          if (
            role.roleName === 'WTM.Compliance.Edit'
          ) {
            userrole.complianceExpert = true;
          }

        }

      localStorage.setItem(
        'usersessionname',
        res.firstName + ' ' + res.lastName
      );

      localStorage.setItem('userId', res.userId);

      localStorage.setItem(
        'usersessionname',
        res.firstName + ' ' + res.lastName
      );

      localStorage.setItem('userId', res.userId);

      localStorage.setItem('organisationId', res.organisationId);

      userrole.firstName=res.firstName;
      userrole.lastName=res.lastName;
      }

      this.userroles$ = new BehaviorSubject<Roles | null>(userrole);
      this.pokeRoles(userrole);

      // store user roles

      this.cookies.setCookie(constants.cookieRolesName, userrole);

      // store cookie data
      this.cookies.setCookie(constants.cookieDataName, 'true');



    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  userroles$ = new BehaviorSubject<Roles | null>(null);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  profile$ = new BehaviorSubject<Profile | null>(null);

  watchProfile(): Observable<Profile | null> {
    return this.profile$;
  }
  peekProfile(): Profile | null {
    return this.profile$.value;
  }
  pokeProfile(profile: Profile): void {
    this.profile$.next(profile);
  }

  watchUserRoles(): Observable<Roles | null> {
    return this.userroles$;
  }
  peekRoles(): Roles | null {
    return this.userroles$.value;
  }

  pokeRoles(role: Roles): void {
    this.userroles$.next(role);
  }

  clearRoles(userrole: Roles) {
    this.userroles$.next(userrole);
  }

  private handleError(error: any, value = 0) {
    console.log('HANDLE ERROR', error);
    console.log('HANDLE ERROR VALUE', value);
    throw error;
    return error;
  }
}

export class User {
  confCode: string;
  created: string;
  email: string;
  firstName: string;
  isEnabled: string;
  lastLogin: string;
  lastName: string;
  password: string;
  phone: string;
  userId: string;
  roles: any;
}
